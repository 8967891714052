export default function verticalBarChartSpec(
    question,
    data,
    yField,
    yDomain,
    xTitle,
    yTitle,
    percent = false,
    overlay = true,
    markColor = "#81d4fa",
    highlightColor = "#ffcc80",
    selectColor = "#ca9b52",
  ) {
    const label = `join([datum.${yField}, "|", if(datum.${yField}_count === datum.${yField}_total_count, "", join([format(datum.${yField}_count, ".0f"), " (", format(datum.${yField}_filtered_percent, ".2%"), " of bar, ", format(datum.${yField}_filtered_percent_of_total, ".2%"), " of total)|Bar total: "], "")), format(datum.${yField}_total_count, ".0f"),
      " (", format(datum.${yField}_total_percent, ".2%"), " of total)"], "")`;
console.log(label);

    return {
      $schema: "https://vega.github.io/schema/vega-lite/v5.json",
      width: "container",
      data: {
        // name: "whole",
        values: data,
      },
      transform: [
        {
          // 0: highlighted
          // 1: normal
          calculate: `if(indexof(filter_dependent, datum.id) !== -1, 0, 1)`,
          as: "filtered",
        },
        {
          aggregate: [{
            op: 'count',
            field: yField,
            as: `${yField}_count`,
          }],
          groupby: [yField, "filtered"],
        },
        {
          filter: overlay ? 'true' : 'datum.filtered === 0',
        },
        {
          calculate: `if(datum.filtered === 0, "${highlightColor}", "${markColor}")`,
          as: "filter_color",
        },
        {
          joinaggregate: [
            {
              op: 'sum',
              field: `${yField}_count`,
              as: `${yField}_total_count`,
            },
          ],
          groupby: [yField],
        },
        {
          joinaggregate: [
            {
              op: 'sum',
              field: `${yField}_count`,
              as: 'total_count',
            },
          ],
          groupby: [],
        },
        {
          calculate: `datum.${yField}_count/datum.total_count`,
          as: `${yField}_percent`
        },
        {
          calculate: `datum.${yField}_total_count/datum.total_count`,
          as: `${yField}_total_percent`
        },
        {
          calculate: `datum.${yField}_count/datum.${yField}_total_count`,
          as: `${yField}_filtered_percent`
        },
        {
          calculate: `datum.${yField}_count/datum.total_count`,
          as: `${yField}_filtered_percent_of_total`
        },
        {
          calculate: label,
          as: 'label'
        },
      ],
      mark: {
        type: "bar",
        height: { band: 0.1 },
      },
      encoding: {
        x: {
          field: `${yField}_${percent ? 'percent' : 'count'}`,
          type: 'quantitative',
          title: xTitle,
          axis: percent ? { format: ".0%" } : { tickMinStep: 1, },
          scale: percent ? { domain: [0, 1] } : undefined,
          stack: "zero",
        },
        y: {
          field: yField,
          type: "nominal",
          title: yTitle,
          scale: { domain: yDomain },
          axis: null,
        },
        color: {
          field: "filtered",
          type: "nominal",
          scale: { range: { field: "filter_color" } },
          legend: null,
        },
        tooltip: {
          field: 'label',
          type: 'nominal',
        },
      },
  
      layer: [
        {
          params: [
            //   {"name": "brush", "select": {"type": "interval", "encodings": ["y"]}},
            {
              name: "select",
              select: { type: "point", fields: [yField] },
            },
          ],
          mark: { type: "bar", color: markColor },
          encoding: {
            x: {
              field: `${yField}_${percent ? 'percent' : 'count'}`,
              type: 'quantitative',
            },
          },
          // encoding: {
          //   x: { aggregate: "count", title: xTitle, axis: { tickMinStep: 1 } },
          //   y: {
          //     field: yField,
          //     type: "nominal",
          //     title: yTitle,
          //     scale: { domain: yDomain },
          //   },
          // },
        },
        {
          transform: [
            {
              calculate: `if(indexof(filter_selection, join(["${question}|", datum.${yField}], "")) !== -1, "${selectColor}", "transparent")`,
              as: "stroke",
            },
          ],
          mark: { type: "bar", height: { band: 0.8 } },
          encoding: {
            x: {
              field: `${yField}_${percent ? 'percent' : 'count'}`,
              type: 'quantitative',
              axis: percent ? { format: ".0%" } : { tickMinStep: 1, },
              scale: percent ? { domain: [0, 1] } : undefined,
            },
            y: {
              field: yField,
              type: "nominal",
              scale: { domain: yDomain },
            },
            color: { value: 'transparent' },
            stroke: {
              field: 'stroke',
              type: 'nominal',
              scale: { range: { field: 'stroke' } },
              legend: null,
            },
            strokeWidth: { value: 3 },
          },
        },
        {
          mark: { type: "bar", height: { band: 0.8 } },
          encoding: {
            x: {
              field: `${yField}_${percent ? 'percent' : 'count'}`,
              type: 'quantitative',
              axis: percent ? { format: ".0%" } : { tickMinStep: 1, },
              scale: percent ? { domain: [0, 1] } : undefined,
            },
            y: {
              field: yField,
              type: "nominal",
              scale: { domain: yDomain },
            },
          },
        },
        {
          transform: [
            {
              calculate: `if(indexof(filter_selection, join(["${question}|", datum.${yField}], "")) !== -1, "bold", "normal")`,
              as: "weight",
            },
            {
              calculate: `if(indexof(filter_selection, join(["${question}|", datum.${yField}], "")) !== -1, "italic", "normal")`,
              as: "style",
            },
            // aggregate by yField so there is only 1 label per yField
            {
              aggregate: [{
                op: 'count',
                as: 'agg',
              }],
              groupby: [yField, "weight", "style"],
            },
          ],
          mark: {
            type: "text",
            limit: 120,
            align: "left",
            x: 5,
            fontWeight: { expr: 'datum.weight' },
            fontStyle: { expr: 'datum.style' },
          },
          encoding: {
            tooltip: null,
            x: null,
            text: { field: yField },
            color: { value: 'black' },
          },
        },
      ],
    };
  }
  
  export function addFilterSignalBarChart(vegaSpec) {
    //vega spec
  
    vegaSpec.signals.push({
      name: "filter_selection",
      value: [],
    });
  
    vegaSpec.signals.push({
      name: "filter_dependent",
      value: [],
    });
  }
  
