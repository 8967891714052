import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { Vega } from 'react-vega'
import { Handler, escapeHTML } from 'vega-tooltip';
import verticalBarChartSpec, {
  addFilterSignalBarChart,
} from "utils/verticalBarChartSpec";
import { compile } from "vega-lite/build/vega-lite";
import Box from "@mui/material/Box";

import ChartContext from "./ChartContext";

const style = { display: "block" };

export default function Chart(props) {
  const { question, setHeight } = props;
  const { data: fullData, options: { showPercent, questionSelect, ids, overlay, startHeight, numSelected }, functions: { toggleSelect } } = useContext(ChartContext);
  const { data, options, optionLabels } = fullData[question];
  const ref = useRef(null);
  const prevHeight = useRef(startHeight);
  const [view, setView] = useState(null);

  const domain = optionLabels || options;
  // console.log(fullData[question], domain);

  const tooltip = useMemo(() => {
    const tooltipOptions = {
      sanitize: (value) => {
        const parts = value.split("|").map(escapeHTML)
        return parts.map((p, i) => i === 0 ? `<strong>${p}</strong>` : p).join("<br />");
      }
    };

    const tooltip = new Handler(tooltipOptions).call;
    return tooltip;
  }, []);

  const spec = useMemo(() => {
    const liteSpec = verticalBarChartSpec(
      question,
      undefined,
      "value",
      domain,
      `${showPercent ? 'Percent' : 'Number'} of Responses`,
      null,
      showPercent,
      numSelected <= 0 || overlay,
    );
    const vegaSpec = compile(liteSpec).spec;
    addFilterSignalBarChart(vegaSpec);
    return vegaSpec;
  }, [question, domain, showPercent, numSelected, overlay]);

  const keyData = useMemo(() => ({ source_0: data }), [data]);

  const updateHeight = useCallback(() => {
    if (ref.current && ref.current.offsetHeight > 0 && ref.current.offsetHeight !== prevHeight.current) {
      const height = ref.current.offsetHeight;
      requestAnimationFrame(() => setHeight(height));
      prevHeight.current = height;
    }
  }, [setHeight]);

  const onNewView = useCallback((newView) => {
    updateHeight();
    setView(newView);
  }, [updateHeight]);

  useEffect(() => {
    if (!view) return;
    view.signal("filter_selection", questionSelect).runAsync();
    view.signal("filter_dependent", ids).runAsync();
  }, [view, questionSelect, ids]);

  const onError = useCallback((error) => {
    console.error(error);
  }, []);

  updateHeight();

  const handleSelect = useCallback((_name, selected) => {
    if (selected.value) {
      const sel = selected.value[0]
      toggleSelect(question, sel);
    }
  }, [toggleSelect, question]);

  return (
    <Box ref={ref} width="100%">
      <Vega
        style={style}
        spec={spec}
        data={keyData}
        actions={false}
        tooltip={tooltip}
        signalListeners={{ select: handleSelect }}
        onNewView={onNewView}
        onError={onError}
      />
    </Box>
  );
}
