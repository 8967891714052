import logo from "./logo.svg";
import "./App.css";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "components/Navbar.js";
import DashboardHousehold from "pages/DashboardHousehold.js";
import DashboardTeacher from "pages/DashboardTeacher.js";
import Home from "pages/Home.js";
import Literature from "pages/Literature.js";
import Staff from "pages/Staff.js";
import Container from "@mui/material/Container";
import DashboardStudent from "pages/DashboardStudent.js";
import HouseholdInterview from "pages/HouseholdInterview.js"
import Publications from "pages/Publications.js"
import Achievement from "pages/Achievement";

function App() {
  console.log(process.env.PUBLIC_URL)
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Container>
         
          <Route path="/staff">
            <Staff />
          </Route>
          <Route path="/publications">
            <Publications/>
          </Route>
          <Route path="/dashboard/household">
            <DashboardHousehold />
          </Route>
          <Route path="/household-interview">
            <HouseholdInterview/>
          </Route>
          <Route path="/dashboard/teacher">
            <DashboardTeacher />
          </Route>
          <Route path="/dashboard/student">
            <DashboardStudent />
          </Route>
          <Route path="/achievement">
            <Achievement />
          </Route>
          <Route path="/literature">
            <Literature />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
        </Container>
      </div>
    </Router>
  );
}

export default App;
