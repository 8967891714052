import React from 'react';

const defaultOptions = {
  showPercent: false,
  startHeight: 126,
  questionSelect: [],
  ids: [],
  numSelected: -1,
  selectedQuestions: [],
  overlay: true,
  tagDefinitions: {},
};

const defaultFunctions = {
  toggleSelect: () => {},
  setSelectedQuestions: () => {},
};

export const defaultState = {
  options: defaultOptions,
  functions: defaultFunctions,
  data: [],
};

const ChartContext = React.createContext(defaultState);

export default ChartContext;
