import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDrag, useDrop } from 'react-dnd';

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";

import Chart from "./Chart";
import ChartContext from "./ChartContext";
import ChartTag from "./ChartTag";

const ItemTypes = {
  CHART: 'chart',
};

const useStyles = makeStyles((theme) => ({
  chartWrapper: {
    position: 'relative',
  },
  chartClassification: {
    position: 'absolute',
    display: 'block',
    left: theme.spacing(-2),
    top: 0,
    margin: theme.spacing(2),
  },
  chartTitle: {
    textAlign: "center",
    margin: theme.spacing(2, 2, 2, 4),
  },
}));

const reorder = (list, startIndex, endIndex) => {
  if (endIndex === startIndex) return list;
  if (endIndex > startIndex) endIndex -= 1;
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function Placeholder(props) {
  const { dropRef, index, moveTo } = props;

  const [, drop] = useDrop(() => ({
    accept: ItemTypes.CHART,
    drop: () => moveTo(index),
    collect: (monitor) => ({
      isOver: !!monitor.isOver()
    })
  }), [index, moveTo]);

  return (
    <Grid
      ref={dropRef || drop}
      item
      xs={12}
      md={4}
      style={{ minHeight: 150 }}
    />
  );
}

function ChartWrapper(props) {
  const classes = useStyles();

  const { index, tag, title, height, setDragging, moveTo, question, setHeight } = props;

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.CHART,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    }),
  }));

  const [{ isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.CHART,
    drop: () => moveTo(index),
    collect: (monitor) => ({
      isOver: !!monitor.isOver()
    })
  }), [index, moveTo]);

  useEffect(() => {
    if (isDragging) {
      setDragging(index);
    }
  }, [index, setDragging, isDragging]);

  return (
    <>
      {!isDragging && isOver && 
        <Placeholder dropRef={drop} />
      }
      <Grid
        item
        ref={(!isDragging && isOver) ? undefined : drop}
        style={{
          ...(isDragging ? { position: 'fixed', top: -1000, left: -1000 } : {}),
          opacity: isDragging ? 0.5 : 1,
          backgroundColor:"white",
          cursor:"grab"
        }}
        xs={12}
        md={4}
      >
        <Grid ref={drag} container direction="column" style={{ height: '100%' }}>
          <Grid item xs flex="0" className={classes.chartWrapper}>
            <Box className={classes.chartClassification}>
              {tag}
            </Box>
            <Typography
              className={classes.chartTitle}
              variant="body1"
              display="block"
              gutterBottom
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs flex="1" />
          <Grid item xs flex="0">
            <div style={{ height, position: 'relative' }}>
              <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                <Chart
                  question={question}
                  setHeight={setHeight}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default function Charts(props) {
  const { labelMap } = props;
  const { data, options, functions } = useContext(ChartContext);
  const { selectedQuestions, startHeight, tagDefinitions } = options;
  const { setSelectedQuestions } = functions;

  const [dragging, setDragging] = useState(-1);
  const [heights, setHeights] = useState({});

  const questionData = useMemo(() => data && labelMap && Object.keys(data)
    .sort((a, b) => {
      let ai = selectedQuestions.indexOf(a);
      let bi = selectedQuestions.indexOf(b);
      if (ai === -1 && bi === -1) {
        return 0;
      } else if (ai === -1) {
        return 1;
      } else if (bi === -1) {
        return -1;
      }
      return ai - bi;
    })
    .map((qid) => [qid, labelMap[qid].question]), [data, labelMap, selectedQuestions]);

  const rowHeights = useMemo(() => {
    if (!questionData) return [];

    const rowHeights = [];
    questionData.forEach(([qid], i) => {
      const row = Math.floor(i / 3);
      if (rowHeights.length <= row) {
        rowHeights.push(0);
      }

      rowHeights[row] = Math.max(rowHeights[row] || 0, heights[qid] || startHeight);
    });

    return rowHeights;
  }, [heights, startHeight, questionData]);

  if (!data || !labelMap) {
    return null;
  }

  const setHeight = (qid, height) => {
    setHeights((prev) => ({ ...prev, [qid]: height }));
  };

  const moveTo = (index) => {
    setSelectedQuestions(reorder(selectedQuestions, dragging, index));
    // Clear the dragging after the above is done rendering, otherwise dragging would be overwritten.
    window.requestAnimationFrame(() => setDragging(-1));
  }

  return (
    <>
      {questionData
          .map(([qid, q], i) => {
            const row = Math.floor(i / 3);
            const tagDef = tagDefinitions.find((td) => td.filter(qid) === true);
            return (
              <ChartWrapper
                key={qid}
                index={i}
                tag={<ChartTag options={tagDef} />}
                title={q}
                height={rowHeights[row] || startHeight}
                setDragging={setDragging}
                moveTo={moveTo}
                question={qid}
                setHeight={(height) => setHeight(qid, height)}
              />
            );
          })}
      {dragging > -1 && <Placeholder index={selectedQuestions.length} moveTo={moveTo} />}
    </>
  );
}

