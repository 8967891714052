import React from "react";
import { Link as RouterLink } from "react-router-dom";

// import { Link as RouterLink, useLocation } from "react-router-dom";

// import AppBar from "@mui/material/AppBar";
// import Toolbar from "@mui/material/Toolbar";
// import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import Grid from "@mui/material/Grid";

// import hcs from "../images/hcs_edconnect_logo.png"
// import hcde from "../images/hcde.png"
// import enterprisectr from "../images/enterprise_center.png"
// import epb from "../images/epb_fiber_optics.jpg"
// import benton from "../images/benson_institute.png"

const links = [
    [
        "infrastructure",
        "https://qz.com/1996234/the-best-broadband-in-the-us-is-in-chattanooga-tn/",
    ],
    [
        "public/private partnership",
        "https://www.hcde.org/newsroom/h_c_s_ed_connect_bridges_the_digital_divide",
    ],
    [
        "HCS EdConnect, powered by EPB",
        "https://www.edconnect.org/2020/06/15/welcome/",
    ],
    ["inter-disciplinary Boston College team", "/staff", RouterLink],
    ["research team", "/staff", RouterLink],
    ["household survey", "/dashboard/household", RouterLink],
    ["achievement", "/achievement", RouterLink],
    ["student survey", "/dashboard/student", RouterLink],
]
    .map(([text, url, component]) => ({
        [text]: (
            <Link
                href={url}
                to={url}
                component={component}
                target="_blank norel noopener"
            >
                {text}
            </Link>
        ),
    }))
    .reduce((links, link) => Object.assign(links, link), {});

function Home() {
    //   const loc = useLocation();

    return (
        <>
            <Box
                sx={{ "& > img": { display: "block", margin: "0 auto" } }}
                m={5}
            >
                <img
                    src={
                        process.env.PUBLIC_URL +
                        "/images/hcs_edconnect_logo.png"
                    }
                    alt="hcs edconnect logo"
                />
            </Box>
            <Typography gutterBottom>
                As the long-term inequity of reliable home-based internet access
                reached a new urgency in the COVID-19 pandemic, it became clear
                that Internet access is a necessity for student and household
                success (Horowitz, 2020; Menzies, 2020). While the COVID-19
                pandemic pushed the capabilities of remote home learning for
                over a billion children displaced from school, the community of
                Chattanooga, TN was positioned differently. A long history of
                community-based technology initiatives and local{" "}
                {links["infrastructure"]} allowed a{" "}
                {links["public/private partnership"]} to scale United State’s
                most robust community broadband initiative:{" "}
                {links["HCS EdConnect, powered by EPB"]}.
            </Typography>
            <Typography gutterBottom>
                Recognizing this opportunity for research and evaluation, an{" "}
                {links["inter-disciplinary Boston College team"]} is helping
                project stakeholders better capture and analyze the formative
                and summative impacts of the broadband initiative. This website
                provides local stakeholders, researchers, and the greater public
                the resources, instruments, and data supporting and summarizing
                the empirical study of this community broadband initiative’s
                implementation and outcomes.
            </Typography>
            <Typography gutterBottom>
                Highlighted results across the first three years of research and
                evaluation included:
            </Typography>
            <Typography gutterBottom>
                <ul>
                    <li>Households across the community, especially those enrolled in
                the EdConnect program, overwhelmingly believe that technology is
                a positive force for their children’s reading skills, math
                skills, and creativity. (see {links["household survey"]})</li>
                    <li> Most households across the community, including those enrolled
                in EdConnect, report using technology frequently (three or more
                times per month) to interact with their children’s school. (see {links["household survey"]})</li>
                    <li> Households enrolled in the EdConnect program are using digital
                devices as much or more frequently than other households to
                support their children’s education through practices like
                getting information about homework, accessing grades, and
                communicating with teachers. (see {links["household survey"]})</li>
                    <li>Among economically disadvantaged students, EdConnect-enrolled
                8th graders taking ELA and 9th graders taking Algebra 1 tended
                to show more growth in standardized test scores since prior to
                the pandemic, compared to those not enrolled in EdConnect. (see {links["achievement"]})</li>
                <li>
                Compared to students with only decent or poor home internet
                connections, students who reported better home internet
                connections more frequently used the internet to learn new
                things and to discover information that changed their
                opinions.(see {links["student survey"]})</li>
                <li>
                Students who reported better home internet connections were more
                likely to appreciate the internet’s usefulness and take online
                safety/security precautions compared to students who reported
                only decent or poor home internet. (see {links["student survey"]})
                </li>
              </ul>
              
            </Typography>
            <Box mt={5} mb={5}>
                <Typography variant="h6" gutterBottom>
                    The EdConnect Research and Evaluation Study is supported by:
                </Typography>
            </Box>
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={3}
            >
                <Grid item xs={12} sm={6} md={4}>
                    <Link
                        href="https://www.hcde.org/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={process.env.PUBLIC_URL + "/images/hcde.png"}
                            width="100%"
                        />
                    </Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Link
                        href="https://www.theenterprisectr.org/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/images/enterprise_center.png"
                            }
                            width="100%"
                        />
                    </Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Link
                        href="https://epb.com/about/history/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/images/epb_fiber_optics.jpg"
                            }
                            width="100%"
                        />
                    </Link>
                </Grid>
            </Grid>
        </>
    );
}

export default Home;
