import React from "react";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
  iframeFullSize: {
    width: '100%',
    height: '90vh', // Set the height equal to the full height of the viewport
    border: 'none',
    overflow: 'hidden' // Add this line to hide scrollbars
  },
});


function Achievement() {
    const classes = useStyles();
  return (
    <iframe   className={classes.iframeFullSize}
    src="https://docs.google.com/document/d/e/2PACX-1vSd40VNAbVFKyTlRl9s7wZiO1mfjesTARbm50veLpd8frYDHt6AV8zBF-VBU5Sk34Hcowj2ETsA4nsm/pub?embedded=true"
    title="Dstrict Data"
    scrolling="yes"  // Ensure scrolling is enabled
    ></iframe>
  );
}

export default Achievement;
