import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2196f3",
      contrastText: "#fff",
    },
    secondary: {
      main: "#757575",
    },
    // info: {
    //   main: "#1976d2",
    // },
    // error: {
    //   main: "#f44336",
    // },
    // warning: {
    //   main: "#ff9800",
    // },
    // success: {
    //   main: "#4caf50",
    // },
  },
  // typography: {
  //   fontFamily: ['Arial'].join(', ')
  // }
});
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
