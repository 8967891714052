import React, { useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { makeStyles } from "@mui/styles";

import ChartTag from './ChartTag';

const useStyles = makeStyles({
  accordion: {
    '& .MuiFormControlLabel-root': {
      display: 'flex',
      alignItems: 'flex-start',
    },
    '& .MuiFormControlLabel-label': {
      paddingTop: '10px',
    },
  }
});

const Characteristics = (props) => {
  const { data, handleShowQuestions, labels, defaultQuestions, selectedQuestions, tagDefinitions } = props;

  const classes = useStyles();
  const [expanded, setExpanded] = useState(-1);
  const [showExpandHint, setShowExpandHint] = useState(true);

  const handleAccordion = (num) => (_, isExpanded) => {
    setShowExpandHint(false);
    setExpanded(isExpanded ? num : -1);
  };

  return (<>
    <Typography variant="h5" gutterBottom>
      Select Questions to View
      {showExpandHint &&
        <Box component="span" ml={1}><Typography variant="body2" component="span"  >
          (click each to expand)
        </Typography></Box>
      }
    </Typography>
    {tagDefinitions.map((tagDefinition, i) => {
      const matching = Object.keys(data).filter(tagDefinition.filter);
      return (
        <Accordion
          key={JSON.stringify(tagDefinition)}
          expanded={expanded === i}
          onChange={handleAccordion(i)}
          TransitionProps={{ unmountOnExit: false }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box display="flex" alignItems="center">
              <ChartTag options={tagDefinition} />
              <Typography variant="body1" sx={{ ml: 1 }}>{tagDefinition.description}</Typography>
            </Box>

          </AccordionSummary>
          <AccordionDetails className={classes.accordion}>
            {
              matching.map((question) => {
                const checked = tagDefinition.single && selectedQuestions.includes(question);
                return (
                  <FormControlLabel
                    key={question}
                    control={tagDefinition.single ? (
                      <Checkbox
                        onChange={() => handleShowQuestions(question, !checked, matching)}
                        checked={checked}
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<RadioButtonCheckedIcon />}
                      />
                    ) : (
                      <Checkbox
                        onChange={(ev) => handleShowQuestions(question, ev.target.checked)}
                        defaultChecked={defaultQuestions.indexOf(question) > -1}
                      />
                    )}
                    label={labels[question].question}
                  />
                );
              })
            }
          </AccordionDetails>
        </Accordion>
      );
    })}
  </>);
};

export default Characteristics;
