import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { csv } from "d3-fetch";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Tooltip from "@mui/material/Tooltip";

import HelpIcon from '@mui/icons-material/HelpOutline';
import InfoIcon from '@mui/icons-material/InfoOutlined';

import { makeStyles } from "@mui/styles";

import Characteristics from "./Characteristics";
import Charts from "./Charts";
import ChartContext, { defaultState } from "./ChartContext";

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(2),
  },
  chartWrapper: {
    position: 'relative',
  },
  chartClassification: {
    position: 'absolute',
    left: theme.spacing(-2),
    top: 0,
    margin: theme.spacing(2),
  },
  chartTitle: {
    textAlign: "center",
    margin: theme.spacing(2, 2, 2, 4),
  },
  formControl: {
    marginRight: theme.spacing(4),
    minWidth: 300,
    // maxWidth: 400,
  },
  popovernam: {
    height: 500,
  },
}));

const defaultQuestionsHousehold = [
  "Enrollment_Status",
  "Household_Size",  
  "Q1a_f",
  "Q1b_f",
  "Q1c_f",
  "Q1d_f",
];

export function SummaryHousehold() {
  const [responses, setResponses] = useState({"/data/household-responses-2023.csv": true});
  const onChange = useCallback((path) => (ev) => setResponses((r) => ({ ...r, [path]: ev.target.checked })), []);
  return (<>
    <Box sx={{ pb: 2 }} />
    <Typography variant="h5">Response Year: </Typography>
    <FormGroup>
      <FormControlLabel onChange={onChange("/data/household-responses-2021.csv")} control={<Checkbox />} label="2021" />
      <FormControlLabel onChange={onChange("/data/household-responses-2022.csv")} control={<Checkbox  />} label="2022" />
      <FormControlLabel onChange={onChange("/data/household-responses-2023.csv")} control={<Checkbox defaultChecked />} label="2023" />
    </FormGroup>
    <Summary
      responsesPath={Object.entries(responses).filter(([k, v]) => v === true).map(([k, v]) => k)}
      labelsPath="/data/labels.csv"
      metadataPath="/data/metadata.csv"
      defaultQuestions={defaultQuestionsHousehold}
      tagDefinitions={[
        { filter: (id) => !id.startsWith('Q'), label: 'H', color: '#9c27b0', description: 'Household Characteristics' },
        { filter: (id) => id.startsWith('Q'), label: 'S', color: '#cddc39', description: 'Survey Questions' },
      ]}
    />
  </>);
}

const defaultQuestionsTeacher = [
  "dem/which grade",
  "BG/resources_1",
  "use/sup teaching_1",
  "perc/inc vs. dec_1",
  "perc/inc vs. dec_2",
  "perc/inc vs. dec_3",
];

export function SummaryTeacher() {
  return (
    <Summary
      responsesPath="/data/teacher-responses-filter-school.csv"
      labelsPath="/data/teacher-labels-filter-school.csv"
      metadataPath="/data/teacher-metadata-filter-school.csv"
      defaultQuestions={defaultQuestionsTeacher}
      tagDefinitions={[
        { filter: (id) => id.startsWith('dem/'), label: 'D', color: '#eca952', description: 'Demographics', single: true },
        { filter: (id) => id.startsWith('BG/'), label: 'B', color: '#9c27b0', description: 'Background' },
        { filter: (id) => id.startsWith('use/'), label: 'U', color: '#cddc39', description: 'Usage' },
        { filter: (id) => id.startsWith('perc/'), label: 'P', color: '#008bcc', description: 'Perception' },
      ]}
    />
  );
}

export function Summary({ responsesPath, labelsPath, metadataPath, defaultQuestions, tagDefinitions }) {
  const [message, setMessage] = useState(null);
  // const [domains, setDomains] = useState(null);

  const [responses, setResponses] = useState();
  const [labelMap, setLabelMap] = useState();
  const [andSelection, setAndSelection] = useState(true);
  const [selections, setSelections] = useState({});

  const initialState = defaultState;
  defaultState.options.selectedQuestions = defaultQuestions;
  defaultState.options.tagDefinitions = tagDefinitions;

  const [chartsState, setChartsState] = useState(initialState);

  const setOptions = useCallback((options) => {
    setChartsState(({ options: old, ...state }) => {
      if (typeof options === 'function') {
        return { ...state, options: options(old) }
      }
      return { ...state, options };
    });
  }, []);
  const setOption = useCallback((key, value) => {
    setOptions(({ [key]: old, ...options }) => {
      if (typeof value === 'function') {
        return { ...options, [key]: value(old) }
      }
      return { ...options, [key]: value };
    });
  }, [setOptions]);
  const setData = useCallback((data) => {
    setChartsState(({ data: old, ...state }) => {
      if (typeof data === 'function') {
        return { ...state, data: data(old) }
      }
      return { ...state, data };
    });
  }, []);

  const setSelectedQuestions = useCallback((selectedQuestions) =>
    setOption('selectedQuestions', selectedQuestions), [setOption]);
  const setQuestionSelect = useCallback((questionSelect) =>
    setOption('questionSelect', questionSelect), [setOption]);
  const setIds = useCallback((ids) =>
    setOption('ids', ids), [setOption]);
  const setShowPercent = useCallback((showPercent) =>
    setOption('showPercent', showPercent), [setOption]);
  const setOverlay = useCallback((overlay) =>
    setOption('overlay', overlay), [setOption]);
  const setNumSelected = useCallback((numSelected) =>
    setOption('numSelected', numSelected), [setOption]);

  useEffect(() => {
    setChartsState(({ ...state }) => {
      const functions = {
        toggleSelect: (question, sel) => {
          setSelections((selections) => {
            let { [question]: current, ...rest } = selections;

            if (current === undefined) {
              current = [sel];//a new selection
            } else if (current.includes(sel)) {
              current = current.filter((v) => v !== sel);// toggle the selection
            } else {
              current = [...current, sel];// add to existing selection
            }

            if (current.length === 0) {
              return rest;//remove the question from selections
            }

            return { ...rest, [question]: current }; // updated selection
          });
        },
        setSelectedQuestions,
      };

      return { ...state, functions };
    });
  }, [setQuestionSelect, setSelectedQuestions]);

  const data = chartsState.data;
  const options = chartsState.options;
  const [rawData, setRawData] = useState([]);

  useEffect(() => {
    console.log("responsesPath", responsesPath);
    const responsesPaths = Array.isArray(responsesPath) ? responsesPath : [responsesPath];
    Promise.all([
      csv(process.env.PUBLIC_URL + labelsPath),
      csv(process.env.PUBLIC_URL + metadataPath),
      ...responsesPaths.map((path) => csv(process.env.PUBLIC_URL + path)),
    ]).then(([labels, metadata, ...allResponses]) => {
      const responses = allResponses.flat()
      console.log("responses, labels, metadata", responses, labels, metadata);

      setResponses(responses);
      initialize(responses, metadata, labels);
    });
  }, [responsesPath, labelsPath, metadataPath]); // called once

  const [total, setTotal] = useState(0);

  function initialize(responses, metadata, labels) {
    // label map
    const labelMap = labels.reduce((acc, d) => {
      if (!acc[d["Question Name"]]) {
        acc[d["Question Name"]] = {
          question: null,
          options: {},
        };
      }
      if (d["Option Name"] !== "") {
        // option
        acc[d["Question Name"]].options[d["Option Name"]] = d["Display Label"];
      } else {
        // console.log('quesetion', d["Display Label"]);
        // question
        acc[d["Question Name"]].question = d["Display Label"];
      }
      return acc;
    }, {});

    console.log("labelMap", labelMap);

    //transform survey data into display data

    const displayData = {};

    //construct placeholders
    metadata.filter((d) => d["Type"] !== "None").forEach((d) => {
      if (!displayData[d["Question"]]) {
        // console.log("d", d);
        // console.log("d['Question']", d["Question"]);
        // console.log("labelMap[d['Question']]", labelMap[d["Question"]]);
        // console.log("labelMap[d['Question']]", labelMap[d["Question"]]);
        displayData[d["Question"]] = {
          label: labelMap[d["Question"]].question,
          type: d.Type, // determine chart type
          data: [],
          options: null, // determine the data domain (only for bar chart, non histogram)
          optionLabels: null,
        };
      }
      if (d["Options"] !== "") {
        displayData[d.Question].options = d["Options"].split(",");

        const labelExist = d["Options"]
          .split(",")
          .every((option) => labelMap[d["Question"]].options[option]);
        if (labelExist) {
          displayData[d.Question].optionLabels = d["Options"]
            .split(",")
            .map((option) => {
              return labelMap[d["Question"]].options[option];
            });
        }
      }
    }, {});

    responses.forEach((resp, i) => {
      // console.log('resp',resp);
      const id = Symbol(i)
      Object.entries(resp).forEach(([prop, value]) => {
        if (!labelMap[prop]) {
          // not displayed
          return;
        }
        if (value === "") {
          // if missing, skip
          return;
        }
        const qcode = prop.trim();
        if (!displayData[qcode]) {
          return;
        }
        // if (!labelMap[prop].options[value.trim()]){
        //     console.log(value);
        // }

        value.split(",").forEach((val) => {
          displayData[qcode].data.push({
            id, // to enable cross-filtering
            value: labelMap[prop].options[val]
              ? labelMap[prop].options[val]
              : val,
          });
        });
      });
    });
    console.log("displayData", displayData);
    setLabelMap(labelMap);
    setRawData(displayData);
    setTotal(responses.length);
  }

  function filterData(data, selectedQuestions) {
    const filteredData = Object.entries(data)
      .filter((d) => selectedQuestions.includes(d[0]))
      .reduce((acc, d) => {
        acc[d[0]] = d[1];
        return acc;
      }, {});
    // console.log("filteredData", filteredData);
    return filteredData;
  }

  useEffect(() => {
    if (Object.keys(selections).length === 0) {
      setNumSelected(-1);
      setIds([]);
      setQuestionSelect([]);

      return;
    }

    const idsByQuestionValue = Object.entries(selections).flatMap(([question, values]) =>
      values.map((value) =>
        data[question].data.filter((d) => value === d.value)
          .map(d => d.id)));

    let ids;

    if (andSelection) {
      // From https://stackoverflow.com/a/51874332
      ids = idsByQuestionValue.reduce((a, b) => a.filter(c => b.includes(c)));
    } else {
      ids = [...new Set(idsByQuestionValue.flat())]
    }

    const questionSelect = Object.entries(selections).flatMap(([question, values]) =>
      values.map((v) => `${question}|${v}`));
    
    setNumSelected(ids.length);
    setIds(ids);
    setQuestionSelect(questionSelect);
  }, [selections, data, andSelection, setIds, setQuestionSelect, setNumSelected]);

  useEffect(() => {
    if (!rawData) {
      return;
    }
    const filtered = filterData(rawData, options.selectedQuestions, []);
    setData(filtered)
  }, [rawData, options.selectedQuestions, setData]);

  const handleShowQuestions = (question, value, toRemove = []) => setTimeout(() => {
    console.log(question, value);
    if (!value) {
      setSelections((selections) => {
        const { [question]: _, ...rest } = selections;
        return rest;
      });
    }

    setSelectedQuestions((selectedQuestions) => {
      const index = selectedQuestions.indexOf(question);
      if (value === (index > -1)) { // no change
        return selectedQuestions;
      }

      if (index === -1) { // add selected question
        let removed = selectedQuestions;
        if (toRemove.length > 0) {
          removed = selectedQuestions.filter((q) => !toRemove.includes(q));
        }
        console.log([question, ...removed]);
        return [question, ...removed];
      }

      // remove selected question
      return selectedQuestions.filter((_, i) => i !== index);
    });
  }, 100);

  console.log(selections);

  return (
    <>
      {message && <Box p={3}>{message}</Box>}
      {rawData && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ p: 2 }} />
            <Characteristics
              data={rawData}
              handleShowQuestions={handleShowQuestions}
              labels={labelMap}
              defaultQuestions={defaultQuestions}
              selectedQuestions={options.selectedQuestions}
              tagDefinitions={tagDefinitions}
            />


          </Grid>
        </Grid>
      )}
      <Grid container>
        <Grid item xs={12}>
          <Box mt={5} mb={4}>
            <Typography variant="h5" gutterBottom>
              Total&nbsp;Responses:&nbsp;{total}{' '}
              {options.numSelected >= 0 && (<span>
                (Highlighted:&nbsp;{options.numSelected}
                <Tooltip
                  style={{ whiteSpace: 'pre-line' }}
                  disableFocusListener
                  title={<span>
                    Respondent highlighted if matching {andSelection ? 'all' : 'any'} of the following: <br />
                    <ul style={{ margin: 0, paddingLeft: 16 }}>
                      {Object.keys(selections).map((q) => (
                        <li key={q}>{labelMap[q] ? labelMap[q].question : q}: {selections[q].join(', ')}</li>
                      ))}
                    </ul>
                  </span>}
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              )</span>)}
            </Typography>
            
            <Box mt={4}>
              <Typography variant="button" display="block" gutterBottom>Display Options</Typography>
              <FormControl>
                <FormLabel>
                  Display Unit
                  <Tooltip disableFocusListener title="Choose whether to show values as counts or percentages. Counts are scaled so that the longest bar fits the plot, while percentages are fixed at a maximum of 100%.">
                    <IconButton>
                      <HelpIcon />
                    </IconButton>
                  </Tooltip>
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="display"
                  value={options.showPercent ? 'percentages' : 'counts'}
                  onChange={(ev) => setShowPercent(ev.target.value === 'percentages')}
                >
                  <FormControlLabel value="counts" control={<Radio />} label="Counts" />
                  <FormControlLabel value="percentages" control={<Radio />} label="Percentages" />
                </RadioGroup>
              </FormControl>

              <FormControl sx={{marginLeft:3}}>
                <FormLabel>
                  Multi-Select
                  <Tooltip disableFocusListener title="When multiple bars are selected, filter for responses that match either any of the criteria, or all of them.">
                    <IconButton>
                      <HelpIcon />
                    </IconButton>
                  </Tooltip>
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="selection"
                  value={andSelection ? 'and' : 'or'}
                  onChange={(ev) => setAndSelection(ev.target.value === 'and')}
                >
                  <FormControlLabel value="or" control={<Radio />} label="Any" />
                  <FormControlLabel value="and" control={<Radio />} label="All" />
                </RadioGroup>
              </FormControl>

              <FormControl sx={{marginLeft:3}}>
                <FormLabel>
                  Highlighted Display
                  <Tooltip disableFocusListener title="When filtering, display the selection either overlaid on top of, or replacing the total responses.">
                    <IconButton>
                      <HelpIcon />
                    </IconButton>
                  </Tooltip>
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="selection"
                  value={options.overlay ? 'overlay' : 'replace'}
                  onChange={(ev) => setOverlay(ev.target.value === 'overlay')}
                >
                  <FormControlLabel value="overlay" control={<Radio />} label="Overlay" />
                  <FormControlLabel value="replace" control={<Radio />} label="Replace" />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box mt={4}>


              <Typography variant="button" display="block" gutterBottom>Color Legend</Typography>
              {/* <Box display="flex">
                <Box display="flex" alignItems="center">
                  <Box width={18} height={18} display="inline-block" borderRadius={1} backgroundColor="#81d4fa" /> &nbsp;<Typography variant="body2"  >Regular Responses </Typography>
                </Box>
                
              </Box> */}
              
              <Box display="flex">
               <Box display="flex" alignItems="center">
                 <Box width={18} height={18} mr={1} display="inline-block" borderRadius={1} backgroundColor="#81d4fa" />
                 
                 Responses
                </Box>
                <Box display="flex" alignItems="center">
                  <Box ml={2} width={18} height={18}  mr={1} display="inline-block" borderRadius={1} backgroundColor="#ffcc80" />
                   Matching Responses
                </Box>
                <Box ml={2} display="flex" alignItems="center">
                  <Box width={18} height={18} display="inline-block" borderRadius={1} border="2px solid #ca9b52">
                    <Box
                      width={0}
                      height={0}
                      mr={1}
                      borderLeft="9px solid #81d4fa"
                      borderTop="9px solid #81d4fa"
                      borderRight="9px solid #ffcc80"
                      borderBottom="9px solid #ffcc80"
                    />
                  </Box>
                  Selection
                </Box>

              </Box>
              <Box mt={2}>
              🧑‍🏫 Click bars to toggle filtering responses across all questions.
              </Box>
            </Box>
          </Box>
        </Grid>
        <DndProvider backend={HTML5Backend}>
          <ChartContext.Provider value={chartsState}>
            <Charts labelMap={labelMap} />
          </ChartContext.Provider>
        </DndProvider>
      </Grid>
    </>
  );
}
