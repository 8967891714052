import React, { useEffect, useMemo, useState } from "react";
import { csv } from "d3-fetch";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import { makeStyles } from "@mui/styles";

const PEOPLE_URL =
  "https://docs.google.com/spreadsheets/d/1jtvo_yO3abdTbMo34Tzj-iR0h48a20yJfmGn8LHSP2U/export?format=csv&gid=0";

function comparator(a, b) {
  let x = parseInt(a.Order);
  let y = parseInt(b.Order);

  if (x < y) return -1;
  if (x > y) return 1;
  return 0;
}

const useStyles = makeStyles({
  person: {
    margin: "pointer",
  },
});

function Person(props) {
  const { person } = props;

  return (
    <Paper sx={{ my: 4 }} elevation={0}>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={3}>
          <img
            alt={person.Name}
            src={person["Direct Link"]}
            style={{
              display: "block",
              margin: "0 auto",
              height: "auto",
              width: "100%",
              maxWidth: 200,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          <Typography variant="h6">{person.Name}</Typography>
          <Typography variant="button">{person.Title}</Typography>
          <br />
          <Typography variant="button">{person.Institution}</Typography>
          {person.Email && (
            <>
              <br />
              <Typography variant="caption">
                {"Email: "}
                {person.Email && (
                  <Link href={`mailto:${person.Email}`}>{person.Email}</Link>
                )}
              </Typography>
            </>
          )}
          {person.Website1 && (
            <>
              <br />
              <Typography variant="caption">
                {"Website: "}
                {person.Website1 && (
                  <Link href={person.Website1}>{person.Website1}</Link>
                )}
              </Typography>
            </>
          )}
          {person.Website2 && (
            <>
              <br />
              <Typography variant="caption">
                {"Website: "}
                {person.Website2 && (
                  <Link href={person.Website2}>{person.Website2}</Link>
                )}
              </Typography>
            </>
          )}
          <div style={{ height: 20 }} />
          {person.Background && (
            <Typography variant="body1" gutterBottom>
              <strong>Background: </strong>
              {person.Background}
            </Typography>
          )}
          {person.Service && (
            <Typography variant="body1" gutterBottom>
              <strong>
                Organization's Service of the Greater Chattanooga region:{" "}
              </strong>
              {person.Service}
            </Typography>
          )}
          {person.Interest && (
            <Typography variant="body1" gutterBottom>
              <strong>Interests and Hopes: </strong>
              {person.Interest}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}

function Staff() {
  const [staff, setStaff] = useState([]);

  useEffect(() => {
    csv(PEOPLE_URL)
      .then(setStaff)
      .catch((err) => console.error(err));
  }, []);

  console.log(staff.sort(comparator));

  return (
    <>
      <Typography id="research-staff" variant="h5" gutterBottom>
        Research Staff
      </Typography>
      <Typography variant="body1" gutterBottom>
        Supporting the EdConnect evaluation is a multidisciplinary team from
        Boston College’s Lynch School of Education and Morrisey College of Arts
        and Science.
      </Typography>
      {staff &&
        staff
          .filter((person) => person.RoleCategory === "Research Staff")
          .sort(comparator)
          .map((person) => <Person person={person} />)}

      <div style={{ height: 30 }} />

      <Typography id="advisory-board" variant="h5" gutterBottom>
        Community Advisory Board
      </Typography>
      <Typography variant="body2" gutterBottom>
        In addition, the multi-year evaluation is supported by a distinguished
        Community Advisory Board.
      </Typography>
      {staff &&
        staff
          .filter((person) => person.RoleCategory === "Advisory Board")
          .sort(comparator)
          .map((person) => <Person person={person} />)}
      <Typography id="alumni" variant="h5" gutterBottom>
        Alumni
      </Typography>
      <Typography variant="body2" gutterBottom>
        The success of the EdConnect evaluation was made possible with contributions
        from our fantastic past staff members.
      </Typography>

      {staff &&
        staff
          .filter((person) => person.RoleCategory === "Alumni")
          .sort(comparator)
          .map((person) => <Person person={person} />)}
    </>
  );
}

export default Staff;
