import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { csv } from "d3-fetch";

import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";
import Input from "@mui/material/Input";
import Checkbox from "@mui/material/Checkbox";

// import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/lab/Autocomplete';

import verticalBarChartSpec, {
  addFilterSignalBarChart,
} from "utils/verticalBarChartSpec";
import { compile } from "vega-lite/build/vega-lite";
import vegaEmbed from "vega-embed";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(2),
  },
  chartTitle: {
    textAlign: "center",
    margin: theme.spacing(2),
  },
  formControl: {
      boxSizing:"border-box",
    paddingRight: theme.spacing(4),
    minWidth: 300,
    marginBottom: theme.spacing(4),
  },
  popovernam: {
    height: 500,
  },
}));
export default function Relation(props) {
  const classes = useStyles();
  const [message, setMessage] = useState(null);
  // const [domains, setDomains] = useState(null);

  const [optionMap, setOptionMap] = useState();
  const [labelMap, setLabelMap] = useState();
  const [data, setData] = useState();
  const [allQuestions, setAllQuestions] = useState();

  const chartRef = useRef();
  useEffect(() => {
    // csv('/data/demographics.csv').then(data=>{
    //   console.log(data);
    // })
    Promise.all([
      csv(process.env.PUBLIC_URL + "/data/demographics.csv"),
      csv(process.env.PUBLIC_URL + "/data/responses.csv"),
      csv(process.env.PUBLIC_URL + "/data/labels.csv"),
      csv(process.env.PUBLIC_URL + "/data/metadata.csv"),
    ]).then(([demographic, responses, labels, metadata]) => {
      //   console.log(demographic, responses, labels, metadata);

      initialize(responses, metadata, labels);
    });
  }, []); // called once

  const myChartRefs = useRef([]);

  const [selectedQuestions, setSelectedQuestions] = useState([
    "Gender",
    "C_Status"
  ]);
  const [xAxis, setXAxis] = useState(0);
  const [yAxis, setYAxis] = useState(0);
  const [total, setTotal] = useState(0);

  function initialize(responses, metadata, labels) {
    // label map
    const labelMap = labels.reduce((acc, d) => {
      if (!acc[d["Question Name"]]) {
        acc[d["Question Name"]] = {
          question: null,
          options: {},
        };
      }
      if (d["Option Name"] !== "") {
        // option
        acc[d["Question Name"]].options[d["Option Name"]] = d["Display Label"];
      } else {
        // console.log('quesetion', d["Display Label"]);
        // question
        acc[d["Question Name"]].question = d["Display Label"];
      }
      return acc;
    }, {});

    console.log("labelMap", labelMap);

    //transform survey data into display data
    const displayData = [];
    // displayData.columns = responses.columns;
    responses.map((resp) => {
      const datum = {};
      Object.entries(resp).map(([prop, value]) => {
        if (!labelMap[prop] || value === "") {
          // not displayed or value missing, skip
          //   datum[prop] = value;
          return;
        }

        const question = labelMap[prop.trim()].question;
        datum[question] = labelMap[prop].options[value]
          ? labelMap[prop].options[value]
          : value;
      });
      displayData.push(datum);
    });
    const allQuestions = responses.columns.filter((d) =>
      Object.keys(labelMap).includes(d)
    );

    // domains
    const optionMap = {};

    metadata.map((d) => {
      //   if (!displayData[d["Question"]]) {
      //     displayData[d["Question"]] = {
      //       label: labelMap[d["Question"]].question,
      //       type: d.Type, // determine chart type
      //       data: [],
      //       options: null, // determine the data domain (only for bar chart, non histogram)
      //       optionLabels: null,
      //     };
      //   }
      if (d["Options"] !== "") {
        const labelExist = d["Options"]
          .split(",")
          .every((option) => labelMap[d["Question"]].options[option]);
        if (labelExist) {
          optionMap[d["Question"]] = d["Options"].split(",").map((option) => {
            return labelMap[d["Question"]].options[option];
          });
        } else {
          optionMap[d["Question"]] = d["Options"].split(",");
        }
      }
    }, {});

    console.log("displayData", displayData);
    console.log("optionMap", optionMap);
        
    setXAxis("Respondent_Gender");
    setYAxis("Q1a");
    setAllQuestions(allQuestions);
    setOptionMap(optionMap);
    setLabelMap(labelMap);
    setData(displayData);
    setTotal(responses.length);
  }

  function filterData(data, selectedQuestions) {
    const filteredData = Object.entries(data)
      .filter((d) => selectedQuestions.includes(d[0]))
      .reduce((acc, d) => {
        acc[d[0]] = d[1];
        return acc;
      }, {});
    console.log("filteredData", filteredData);
    return filteredData;
  }
  useEffect(() => {
    if (!data) {
      return;
    }
    visualize();
  });
  async function visualize() {
    const container = chartRef.current;
    console.log(xAxis, yAxis)
    const xField = labelMap[xAxis].question;
    const yField = labelMap[yAxis].question;
    const chart = await vegaEmbed(container, {
      $schema: "https://vega.github.io/schema/vega-lite/v5.json",
      width: "container",
      data: {
        // name: "whole",
        values: data,
      },
      transform: [
        {
          aggregate: [{ op: "count", as: "num_responses" }],
          groupby: [xField, yField],
        },
      ],
      encoding: {
        y: { field: yField, type: "ordinal" },
        x: { field: xField, type: "ordinal" },
      },
      layer: [
        {
          mark: "rect",
          encoding: {
            color: {
              field: "num_responses",
              type: "quantitative",
              title: "Count of Records",
              legend: { direction: "horizontal", gradientLength: 120 },
            },
          },
        },
        {
          mark: "text",
          encoding: {
            text: { field: "num_responses", type: "quantitative" },
            color: {
              condition: {
                test: "datum['num_responses'] < 40",
                value: "black",
              },
              value: "white",
            },
          },
        },
      ],
      config: { axis: { grid: true, tickBand: "extent" } },
    });
  }

  function handleShowQuestions(e) {
    console.log("questions", selectedQuestions, e.target.value);
    setSelectedQuestions(e.target.value);
  }
  function handleXAxis(e) {
    setXAxis(e.target.value);
  }
  function handleYAxis(e) {
      console.log('yAxis', e.target.value);
    setYAxis(e.target.value);
  }
  return (
    <Container>
      {message && <Box p={3}>{message}</Box>}
      {data && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {/* <FormControl className={classes.formControl} fullWidth>
              <InputLabel id="demo-mutiple-checkbox-label">
                Select questions to visualize
              </InputLabel>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={selectedQuestions}
                onChange={handleShowQuestions}
                input={<Input />}
                renderValue={(selected) =>
                  selected.map((d) => labelMap[d].question).join(", ")
                }
                MenuProps={MenuProps}
              >
                {data.columns.map((question) => (
                  <MenuItem key={question} value={question}>
                    <Checkbox
                      checked={selectedQuestions.indexOf(question) > -1}
                    />
                    <ListItemText primary={labelMap[question].question} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </Grid>
        </Grid>
      )}
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Box mb={4}>
                <Typography variant="button" gutterBottom>
                  Total Responses: {total}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {data && (
          <Grid item xs={12}>
            <Grid container spacing={3} sx={{ mb: 4 }}>
              <Grid item xs={6}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="select-x-question">Select X-axis</InputLabel>
                  <Select
                    labelId="select-x-question"
                    value={xAxis}
                    onChange={handleXAxis}
                    MenuProps={MenuProps}
                    variant="standard"
                  >
                    {allQuestions.map((question) => (
                        <MenuItem key={question} value={question}>
                          {labelMap[question].question}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="select-y-question">Select Y-axis</InputLabel>
                  <Select
                    labelId="select-y-question"
                    value={yAxis}
                    onChange={handleYAxis}
                    MenuProps={MenuProps}
                    variant="standard"
                  >
                    {allQuestions.map((question) => (
                        <MenuItem key={question} value={question}>
                          {labelMap[question].question}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12}>
          <Box ref={(el) => (chartRef.current = el)} width="70%"></Box>
        </Grid>
      </Grid>
    </Container>
  );
}
