import React from "react";
// import { Link as RouterLink, useLocation } from "react-router-dom";

// import AppBar from "@mui/material/AppBar";
// import Toolbar from "@mui/material/Toolbar";
// import Button from "@mui/material/Button";
// import Link from "@mui/material/Link";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "components/TabPanel";
import a11yProps from "utils/a11yProps";
import Typography from "@mui/material/Typography";
import { SummaryHousehold } from "components/Summary";
import Relation from "components/Relation";
import Data from "components/Data";
import { styled } from "@mui/styles";
import Grid from "@mui/material/Grid";

import Box from "@mui/material/Box";
import { Fab } from "@mui/material";
// import VisibilityIcon from "@mui/icons-material/Visibility";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     marginBottom: theme.spacing(3),
//     padding: theme.spacing(0.5),
//   },
//   title: {
//     flexGrow: 1,
//   },
// }));

const StyledTab = styled(Tab)(({ theme }) => ({
  padding: theme.spacing(0, 6),
}));

function DashboardStudent() {
  //   const loc = useLocation();
  const [showMore, setShowMore] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={9} wrap>
          <Typography variant="h4" gutterBottom>
            EdConnect 2023 Student Survey Results
          </Typography>
          <Typography variant="body1" gutterBottom>
            The provision of free home internet by EdConnect to students' households enhances the flexibility of remote learning, offering students the opportunity to engage with technology for diverse purposes beyond the traditional classroom setting.  As such, a pilot student survey was developed and conducted to gather information on students' experiences using technology in school and at home, as well as their perceptions and beliefs toward technology.
          </Typography>
          <Typography variant="body1" gutterBottom>
            The Spring 2023 pilot survey collected data from 296 high school students (Grade 9 to 12) from a public high school in Hamilton County, Tennessee. Although the pilot sample was not robust or representative, even the limited student responses offer an important window into student voices and practices.
          </Typography>
          <Typography variant="body1" gutterBottom>
            For example, the 2023 pilot results demonstrated students' frequent use of technology for purposes like exploring their own personal interests, and looking for information online for school.
          </Typography>

          <Box my={6}>
            <Typography variant="subtitle1" gutterBottom>
              Frequency of students’ use of digital devices (Spring 2023 pilot results)
            </Typography>

            <img
              width="100%"
              alt="stacked bar chart showing the digital device usage"
              src="/images/student-survey-figure1-2023.png"
            />
          </Box>
          <Box my={6}>
            <Typography variant="subtitle1" gutterBottom>
              Exploring student beliefs, the Spring 2023 pilot results generally found most students exhibited positive sentiments towards educational technology.  For example, more than half of surveyed high school students reported they wrote better and faster on a computer.


            </Typography>
            <img
              width="100%"
              alt="stacked bar chart showing students' belief about digital devices"
              src="/images/student-survey-figure2-2023.png"
            />
          </Box>
          <Box my={6}>
            <Typography variant="body1" gutterBottom>
              When asked about home connectivity, nearly all students reported at least some access to home internet. Exploring the Spring 2023 sample more closely, we compare students who self-reported their home internet connections as “excellent” (63% of sample) to students who self-reported their home internet connection as “decent” and “poor” (37% of sample). In the figures below, we explore student practices across the 2023 Spring pilot results for differences in students’ technology practices, digital skills, and beliefs.  Examining student practices first, students who reported better home internet connections more frequently used technology to comment on social media, discover important information online, and discover information that changes their opinions compared to students who reported only decent or poor home internet.

            </Typography>
            <img
              width="100%"
              alt="stacked bar chart showing students' belief about digital devices"
              src="/images/student-survey-finding1.png"
            />
          </Box>
          <Box my={6}>
            <Typography variant="body1" gutterBottom>
              Continuing, students who reported better home internet connections consistently self-reported higher digital skills, on average, and greater independence/ease in carrying out various computer-related tasks than students with poor or decent connectivity.
            </Typography>
            <img
              width="100%"
              alt="stacked bar chart showing students' belief about digital devices"
              src="/images/student-survey-finding2.png"
            />
          </Box>
          <Box my={6}>
            <Typography variant="body1" gutterBottom>
              In addition, students who reported better home internet connections exhibited more agreement towards utility, entertainment, and security aspects of the Internet compared to students with only decent or poor home internet connections.
            </Typography>
            <img
              width="100%"
              alt="stacked bar chart showing students' belief about digital devices"
              src="/images/student-survey-finding3.png"
            />
          </Box>

          <Typography variant="body1" gutterBottom>
            Here is a PDF copy of the Spring 2023 HCS EdConnect student survey:
          </Typography>
          <Box mt={4} mb={5}>
            <Fab
              color="primary"
              variant="extended"
              href="/HCS student survey 2023.pdf"
              target="_blank"
            >
              2023 STUDENT SURVEY
            </Fab>
          </Box>
          {/* <Button
            variant="text"
            href="/HCS student survey 2022.pdf"
            target="_blank"
          >
            View Student Survey Form
          </Button> */}

          <Typography variant="body1" gutterBottom>
            Even though student names or identifying information were not collected in the student survey, a parent consent letter was developed to allow for active parent consent. Here is a PDF copy of the Student Survey Parent Consent Form.
          </Typography>
          <Box mt={4} mb={5}>
            <Fab
              color="primary"
              variant="extended"
              href="/Parent Consent Letter.pdf"
              target="_blank"
            >
              View Parent Consent Form for Student Survey
            </Fab>
          </Box>
          {/* 
          <Button
            variant="text"
            href="/Parent Consent Letter.pdf"
            target="_blank"
          >
            View Parent Consent Form for Student Survey
          </Button> */}
        </Grid>
      </Grid>

      {/* {showMore && (
        <>
          <Typography variant="body1" gutterBottom mt={2}>
            For each year, a stratified random sample of all district households
            was recruited and surveyed about their use of and beliefs toward
            digital devices to support learning. The stratified random sample
            was designed to reflect the different levels of program adoption
            among households eligible for the district-wide broadband
            initiative. To help further focus our sample and the resulting data,
            we sampled only among those eligible households with at least one
            child currently in grades 5-8.
          </Typography>
          <Typography variant="body1" gutterBottom>
            The four categories of broadband program participation include “EPB
            active” households who had successfully been signed up and onboarded
            for the free program, “Needs to contact provider” and “Eligible, but
            no response” households had not yet completed the enrollment
            process, and “Does not want service” households had opted out of
            EdConnect.
          </Typography>
          <Typography variant="body1" gutterBottom>
            In addition to participation status, a range of demographic
            information (respondent gender, free/reduced lunch status,
            race/ethnicity, number of children in household, etc.) were provided
            by the school district and public utility service to provide greater
            context and allow richer exploration of the results.
          </Typography>
        </>
      )} */}
      {/* <Button variant="text" onClick={() => setShowMore((prev) => !prev)}>
        {showMore ? "Collapse Description" : "Expand Description"}
      </Button> */}
      <br />
    </>
  );
}

export default DashboardStudent;
