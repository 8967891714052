import React from 'react';
import Typography from "@mui/material/Typography";
const ChartTag = ({ options }) => {
  return options && (
    <div
      style={{
        color: '#ffffff',
        background: options.color,
        borderRadius: 4,
        width: 20,
        height: 20,
        margin:"4px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant='caption' sx={{ fontWeight: 'bold' }}>
        {options.label}
      </Typography>
    </div>
  );
};

export default ChartTag;
