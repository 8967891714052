import React from "react";
// import { Link as RouterLink, useLocation } from "react-router-dom";

// import AppBar from "@mui/material/AppBar";
// import Toolbar from "@mui/material/Toolbar";
// import Button from "@mui/material/Button";
// import Link from "@mui/material/Link";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "components/TabPanel";
import a11yProps from "utils/a11yProps";
import Typography from "@mui/material/Typography";
import { SummaryHousehold } from "components/Summary";
import Relation from "components/Relation";
import Data from "components/Data";
import { styled } from "@mui/styles";
import Grid from "@mui/material/Grid";

// import Box from "@mui/material/Box";
// import VisibilityIcon from "@mui/icons-material/Visibility";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     marginBottom: theme.spacing(3),
//     padding: theme.spacing(0.5),
//   },
//   title: {
//     flexGrow: 1,
//   },
// }));

const StyledTab = styled(Tab)(({ theme }) => ({
  padding: theme.spacing(0, 6),
}));

function DashboardHousehold() {
  //   const loc = useLocation();
  const [showMore, setShowMore] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Typography variant="h4" gutterBottom>
        EdConnect 2021-23 Household Survey Results
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} wrap>
          <Typography variant="body1" gutterBottom>
            This dashboard shares data from three administrations of telephone
            surveys given in 2021, 2022, and 2023 on the practices and
            perspectives of parents toward media, digital devices, and
            educational technology. As part of a larger research and evaluation
            study on the long-term educational and community impacts of the
            district-wide EdConnect broadband initiative, telephone surveys were
            completed with a random sample of about 400 public school households
            each year.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} wrap>
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/k86FBDMCXms"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Grid>
      </Grid>

      {showMore && (
        <>
          <Typography variant="body1" gutterBottom mt={2}>
            For each year, a stratified random sample of all district households
            was recruited and surveyed about their use of and beliefs toward
            digital devices to support learning. The stratified random sample
            was designed to reflect the different levels of program adoption
            among households eligible for the district-wide broadband
            initiative. To help further focus our sample and the resulting data,
            we sampled only among those eligible households with at least one
            child currently in grades 5-8.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Below, "enrolled" households signify those households that have
            enrolled and onboarded for the free program. "Unenrolled"
            households, although eligible for the program, have declined
            service, were non-responsive to offers to join the program, or have
            not completed enrollment in the program.
          </Typography>
          <Typography variant="body1" gutterBottom>
            In addition to participation status, a range of demographic
            information (respondent gender, free/reduced lunch status,
            race/ethnicity, number of children in household, etc.) were provided
            by the school district and public utility service to provide greater
            context and allow richer exploration of the results.
          </Typography>
        </>
      )}
      <Button variant="text" onClick={() => setShowMore((prev) => !prev)}>
        {showMore ? "Collapse Description" : "Expand Description"}
      </Button>
      <br />
      <Button
        variant="text"
        href="/HCS parent survey May 2021.pdf"
        target="_blank"
      >
        View Survey
      </Button>
      {/*
      <AppBar
        position="static"
        color="transparent"
        elevation={0}
        sx={{ mt: 3 }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="menu tabs"
        >
          <StyledTab label="Summary" {...a11yProps(0)} />
          <StyledTab label="Relation" {...a11yProps(1)} />
          {/ * <Tab label="Data" {...a11yProps(2)} /> * /}
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <Summary/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Relation/>
      </TabPanel>
      */}
      <SummaryHousehold />
      {/* <TabPanel value={value} index={2}>
        <Data/>
      </TabPanel> */}
    </>
  );
}

export default DashboardHousehold;
