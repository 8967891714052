import React from "react";

import Button from "@mui/material/Button";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { SummaryTeacher } from "components/Summary";
import { styled } from "@mui/styles";

function Dashboard() {
    const [showMore, setShowMore] = React.useState(false);
    return (
        <>
            <Typography variant="h4" gutterBottom>
                EdConnect 2023 Teacher Survey Results
            </Typography>
            <Typography variant="body1" gutterBottom>
                This data dashboard presents 2023 teacher survey data from 104
                Hamilton County (TN) teachers collected across 52 schools. The
                online survey captured teachers' access to and experiences using
                digital devices and educational technology resources in school
                and at home. The survey also queried teachers' beliefs towards
                educational technology. To provide greater context, the survey
                additionally cataloged teacher and classroom demographic
                background including years taught, grade level, subject area,
                race/ethnicity, and gender.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Please note the current sample of 104 teachers is not
                representative of any individual school or the school district,
                but does provide us an example and model for exploring teacher
                voice and practice.
            </Typography>

            {showMore && (
                <>
                    <Typography variant="body1" gutterBottom mt={2}>
                        Given the focus of the EdConnect initiative on providing
                        students digital equity at home, teacher voice towards
                        home internet access and other obstacles for effective
                        educational technology integration are especially
                        important. Highlighted results from the 2023 survey
                        administration suggest most teachers believe student
                        access to computing devices (92%) as well as home
                        internet (91%) were not major challenges in their
                        community. Additionally, the three greatest challenges
                        reported by teachers were related to parent
                        communication and use of technology (see the figure
                        below).
                    </Typography>
                    <img
                        width="100%"
                        alt="stacked bar chart showing students' belief about digital devices"
                        src="/images/teacher-survey-fingding1.png"
                    />
                </>
            )}
            <Button variant="text" onClick={() => setShowMore((prev) => !prev)}>
                {showMore ? "Collapse Description" : "Expand Description"}
            </Button>
            <br />
            <Button
                variant="text"
                href="/HCS teacher survey 2023.pdf"
                target="_blank"
            >
                View Survey
            </Button>
            <Typography variant="body1" gutterBottom mt={1}>
                Below, an interactive dashboard displays results from the
                initial administration of the teacher survey in 2022. Results
                are not representative of the entire school district, but
                provide an example of how teacher survey responses can be
                examined in detail.
            </Typography>

            <SummaryTeacher />
        </>
    );
}

export default Dashboard;
