import React, { useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { Divider, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Collapse,
  List,
  ListItemButton,
  ListItem,
  ListItemText,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  menu: {
    marginTop: theme.spacing(2),
    position: "fixed",
    top: "30px",
    right: "15px",
    translate: "translateX(50%)",
    background: "#fff",
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
    zIndex: 9999,
  },
  menuDesktop: {
    display: "flex",
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  menuMobile: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
}));
function Navbar() {
  const loc = useLocation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
    console.log("open", open);
  };
  return (
    <AppBar
      position="static"
      color="inherit"
      sx={{
        flexGrow: 1,
        marginBottom: 3,
        padding: 0.5,
        boxShadow: "none",
        borderBottom: "1px solid #e0e0e0",
      }}
    >
      <Toolbar variant="dense">
        <Link
          // variant="h6"
          sx={{
            flexGrow: 1,
            textDecoration: "none",
            ":hover": {
              textDecoration: "none",
            },
          }}
          // underline="none"
          color="inherit"
          component={RouterLink}
          to="/"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& *": { mr: 1, my: 0.5 },
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/images/hcs_edconnect_logo.png"}
              style={{ display: "inline-block" }}
              height={50}
              alt="hcs edconnect logo"
            />
            <Typography variant="subtitle1"><strong>EdConnect Evaluation Study</strong></Typography>
          </Box>
        </Link>

        <div className={classes.menuMobile}>
          <Button onClick={handleToggle}> Menu</Button>
        </div>

        <Collapse
          in={!open}
          className={`${classes.menu} ${classes.menuMobile}`}
        >
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={handleToggle}>
                <Typography color="secondary">Close</Typography>
              </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem disablePadding>
              <ListItemButton component={RouterLink} to="/">
                <ListItemText primary="Home" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={RouterLink} to="/staff">
                <ListItemText primary="Staff" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={RouterLink} to="/dashboard/household">
                <ListItemText primary="Household Survey" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={RouterLink} to="/household-interview">
                <ListItemText primary="Household Interview" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={RouterLink} to="/dashboard/teacher">
                <ListItemText primary="Teacher Survey" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={RouterLink} to="/dashboard/student">
                <ListItemText primary="Student Survey" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={RouterLink} to="/publications">
                <ListItemText primary="Publications" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={RouterLink} to="/achievement">
                <ListItemText primary="Achievement" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={RouterLink} to="/literature">
                <ListItemText primary="Literature" />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse>
        <List className={classes.menuDesktop}>
          <Button
            color={loc.pathname === "/" ? "primary" : "inherit"}
            component={RouterLink}
            to="/"
          >
            <Typography variant="subtitle2">Home</Typography>
          </Button>
          <Button
            color={loc.pathname === "/staff" ? "primary" : "inherit"}
            component={RouterLink}
            to="/staff"
          >
            <Typography variant="subtitle2">Staff</Typography>
          </Button>

          <Button
            color={
              loc.pathname === "/dashboard/household" ? "primary" : "inherit"
            }
            component={RouterLink}
            to="/dashboard/household"
          >
            <Typography variant="subtitle2">Household Survey</Typography>
          </Button>
          <Button
            color={
              loc.pathname === "/household-interview" ? "primary" : "inherit"
            }
            component={RouterLink}
            to="/household-interview"
          >
            <Typography variant="subtitle2">Household Interview</Typography>
          </Button>
          <Button
            color={
              loc.pathname === "/dashboard/teacher" ? "primary" : "inherit"
            }
            component={RouterLink}
            to="/dashboard/teacher"
          >
            <Typography variant="subtitle2">Teacher Survey</Typography>
          </Button>

          <Button
            color={
              loc.pathname === "/dashboard/student" ? "primary" : "inherit"
            }
            component={RouterLink}
            to="/dashboard/student"
          >
            <Typography variant="subtitle2">Student Survey</Typography>
          </Button>
          <Button
            color={
              loc.pathname === "/publications" ? "primary" : "inherit"
            }
            component={RouterLink}
            to="/publications"
          >
            <Typography variant="subtitle2">Publications</Typography>
          </Button>
          <Button
            color={
              loc.pathname === "/achievement" ? "primary" : "inherit"
            }
            component={RouterLink}
            to="/achievement"
          >
            <Typography variant="subtitle2">Achievement</Typography>
          </Button>
          <Button
            color={loc.pathname === "/literature" ? "primary" : "inherit"}
            component={RouterLink}
            to="/literature"
          >
            <Typography variant="subtitle2">Literature</Typography>
          </Button>
        </List>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
