import React from "react";

import Button from "@mui/material/Button";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { SummaryTeacher } from "components/Summary";
import { styled } from "@mui/styles";
import Fab from "@mui/material/Fab";
import Box from "@mui/material/Box";
function HouseholdInterview() {
  return (
    <Grid container spacing={2} mb={5} justifyContent="center">
      <Grid item xs={12} sm={9} wrap>
        <Typography variant="h4" gutterBottom>
          EdConnect HCS 2022-2023 Household Interview
        </Typography>
        <Typography variant="body1" gutterBottom>
          To better document and understand parents&#39; experience and
          sentiment concerning digital equity and technology engagement, local
          households were recruited to participate in a series of semi-
          structured interviews. Each interview was focused around five themes:
          financial impact, general usage as for speed and access, general usage
          as for convenience and daily usage, educational impact, and community
          impact.
          <p>
            Households were recruited through local school networks via fliers
            sent by collaborating principals (e.g., electronic newsletters and
            hard copies). Initially, 31 families expressed interest in
            participating in the interviews and a brief series of screening
            questions were used to select a representative cross-section of
            families, including examples of two-parent, one-parent, grandparent,
            and guardian led households.
          </p>
          <Box mt={4} mb={5}>
            <Fab
              color="primary"
              variant="extended"
              href="/Household Interview Script.pdf"
              target="_blank"
            >
              View Semi-structured Interview Script
            </Fab>
          </Box>
          <p>
            Through our conversations with families, it became apparent that
            access to free WIFI had significant benefits beyond the financial
            savings it provided. Specifically, it enabled families to support
            their Hamilton County Schools (HCS) learners more actively. By
            offering crucial resources such as WIFI and devices, HCS EdConnect
            has the potential to address negative perceptions surrounding
            parental engagement among families of lower socioeconomic status.
            Our findings suggest that parents and guardians from these
            households are just as engaged in their child&#39;s education as
            those from higher socioeconomic backgrounds.
          </p>
          Below are direct quotes from the 2021/2022 household interviews
          organized by themes.
        </Typography>

        <Typography variant="h4" gutterBottom>
          Financial Impact
        </Typography>
        <Typography variant="body1" gutterBottom>
          <p>
            {" "}
            “The financial impact was one burden that was lifted off our
            shoulders. I had paid wifi service through EPB prior to EdConnect.
            It was a seamless transition from going to paid to not paid. I
            couldn&#39;t believe it myself. I think I even called and asked why
            they didn&#39;t take the money out of my account.”
          </p>

          <p>
            “We just struggled being able to afford Internet and keeping it on…
            I hate to admit it, but I’d have to disconnect it from one place,
            and then go to another place and use the promotional and cancel and
            switch again. When we didn&#39;t have it, we would go places like
            McDonald&#39;s parking lot, but it wasn&#39;t always reliable.”
          </p>

          <p>
            “The financial impact is a God-send. All I have is my disability
            check and I&#39;m trying to raise a 6-year-old girl and Internet
            would be the last thing on my list.”
          </p>

          <p>
            “Thank goodness, for it because I was laid off during COVID it made
            things just a little bit easier for us to be able to have it and
            with him being in school, I mean it was tough being in quarantine
            and going to school. We joined EdConnect right before the shutdown
            so that made a big difference in him being able to get his
            schoolwork done. We used WIFI a lot during the quarantine.”
          </p>
        </Typography>
        <Typography variant="h4" gutterBottom>
          Technology Access and Internet Usage
        </Typography>
        <Typography variant="body1" gutterBottom>
          <p>
            “Saving money, great service and speed (when I was paying it was $43
            and it was so slow, this is much better) …helped other families
            learn more about the benefits and encouraged them to sign up.”
          </p>

          <p>
            “One of the reasons the two girls are able to attend virtual school
            is due to having strong internet at home.”
          </p>
        </Typography>
        <Typography variant="h4" gutterBottom>
          General Technology Usage: Convenience &amp; Daily Usage
        </Typography>
        <Typography variant="body1" gutterBottom>
          <p>
            “Appreciate having unlimited access to WIFI. Using the hotspot was
            frustrating with trying to do homework.
          </p>

          <p>
            “We used our cell phones as hotspots up until getting EdConnect. It
            was challenging due to having to monitor data usage and the impact
            of weather on service.”
          </p>

          <p>
            “At one time we did homeschool our children and did not have WIFI at
            home, so we went to the local library daily to use the internet. It
            made it much easier to have a hub at home to help our kids and to
            check their work on a daily basis.
          </p>
        </Typography>

        <Typography variant="h4" gutterBottom>
          Educational Engagement: PowerSchool &amp; Email
        </Typography>
        <Typography variant="body1" gutterBottom>
          <p>
            “I check ClassDojo and email. It has helped me a lot to stay
            involved. It&#39;s hard for me to get around so not having to go in
            the school but still being involved online has been helpful. Being
            able to see ClassDojo on the tablet/laptop to see a larger view and
            see everything, always me to see everything and to be more engaged.
            I can access progress reports and report cards too.”
          </p>

          <p>
            “I took one of those tech goes home classes at their school and that
            really helped a lot. My middle child uses the free computer provided
            by Tech Goes Home.  Prior to joining EdConnect I didn&#39;t realize
            I could get on PowerSchool. Once I found it during the shutdown, I
            realized how useful it was. The youngest uses Cognitive Toolbox and
            I&#39;m able to track him that way. I use ClassDojo every day to
            stay in contact with their teachers.”
          </p>

          <p>
            “I check ClassDojo and email daily. It has helped me a lot to stay
            involved. It&#39;s hard for me to get around so not having to go in
            the school but still being involved online has been helpful. Being
            able to see ClassDojo on the tablet/laptop to see a larger view and
            see everything, always me to see everything and to be more engaged.
            I can access progress reports and report cards too.”
          </p>
        </Typography>

        <Typography variant="h4" gutterBottom>
          Community Engagement: Daily/Weekly Family Engagement:
        </Typography>
        <Typography variant="body1" gutterBottom>
          “I use Facebook groups and HCS social media and website to help stay
          connected.”
        </Typography>
      </Grid>
    </Grid>
  );
}

export default HouseholdInterview;
